import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { getLog } from '@aurora/shared-utils/log';
import type { CoreNode } from '@aurora/shared-generated/types/graphql-schema-types';
import TextVariantContext from '@aurora/shared-client/components/context/TextVariantContext';
import type { ItemType } from '../../../types/enums';
import { NodeViewVariant } from '../../../types/enums';
import type { NodeViewFragment } from '../../../types/graphql-types';
import mergeVariantProps from './NodeViewDefaultProps';
import type { ItemViewFC, ItemViewVariantFC } from '../../entities/types';
import type { NodeViewCommonProps } from './types';

const log = getLog(module);

const NodeViewInline = dynamic(() => import('./NodeViewInline/NodeViewInline'));
const NodeViewCard = dynamic(() => import('./NodeViewCard/NodeViewCard'));

const variantToComponentMap: Record<
  NodeViewVariant,
  ItemViewVariantFC<NodeViewFragment | CoreNode, ItemType.NODE, NodeViewVariant>
> = {
  [NodeViewVariant.CARD]: NodeViewCard,
  [NodeViewVariant.INLINE]: NodeViewInline
};

/**
 * The base node view component that renders the node view specified by
 * the <code>variant</code> parameter. This component should be used anywhere a node is rendered.
 *
 * @author Adam Ayres
 */
const NodeView: ItemViewFC<NodeViewFragment, ItemType.NODE, NodeViewVariant> = ({
  entity: node,
  variant,
  className
}) => {
  const NodeViewComponent = variantToComponentMap[variant.type.toString()];
  const textVariantContext = useContext(TextVariantContext);

  if (NodeViewComponent) {
    const defaultProps = mergeVariantProps<NodeViewCommonProps>(variant);

    return (
      <TextVariantContext.Provider value={{ ...textVariantContext, node }}>
        <NodeViewComponent
          entity={node}
          className={className}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...{ ...defaultProps, ...variant.props }}
        />
      </TextVariantContext.Provider>
    );
  }
  log.warn('No registered node view for variant type [%s]', variant.type);
  return null;
};

export default NodeView;
